type callbacks = {
    onChange: (pos: GeolocationPosition) => void;
    onError: (err: string| GeolocationPositionError) => void;
};

class LocationManager {
    watcher: number | null = null;
    callbacks: callbacks;

    lastLocation: GeolocationPosition | null = null;
    lastLocationTimer: NodeJS.Timeout | null = null;


    constructor(callbacks: callbacks) {
        this.callbacks = callbacks;
        this.connect();
    }

    sendLocation = () => {
        if (this.lastLocation) {
            this.onChange(this.lastLocation);
        }
        this.lastLocationTimer = setTimeout(this.sendLocation, 10000);
    };


    connect = () => {
        const { callbacks } = this;
        if (navigator.geolocation) {
            console.log("setting up watcher");
            this.watcher = navigator.geolocation.watchPosition(
                (pos: GeolocationPosition)=>{
                    this.lastLocation = pos;
                    this.sendLocation();
                },
                this.onError,
                { enableHighAccuracy: true, timeout: 30000 }
            );
            navigator.geolocation.getCurrentPosition(this.onChange, this.onError);
        } else {
            callbacks.onError( "Your device doesn't support geolocation");
        }
    };

    close = () => {
        if (this.watcher != null) {
            navigator.geolocation.clearWatch(this.watcher);
        }
    };

    onChange = (pos: GeolocationPosition) => {
        this.callbacks.onChange(pos);
    };

    onError = (err: GeolocationPositionError) => {
        const server = process.env.REACT_APP_SERVER       ? process.env.REACT_APP_SERVER            : "control.playwithlights.com";

        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open( "GET", `https://${server}/local`, false ); // false for synchronous request
        xmlHttp.send( null );
        if(xmlHttp.responseText == "ok"){
            // no op
            return;
        }

        console.log("on err", err);
        if (err.code === err.TIMEOUT) {
            this.reconnect();
        } else {
            this.callbacks.onError(err);
        }
    };

    reconnect = () => {
        if (this.watcher != null) {
            console.log("reconnecting");
            navigator.geolocation.clearWatch(this.watcher);

            setTimeout(
                () => {
                    return this.connect();
                },
                // alert("trying for updates again")
                1000
            );
        }
    };
}

export default LocationManager;

export function canLocate(): boolean {
    return navigator.geolocation !== undefined;
}
