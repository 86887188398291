/* Do not change, this code is generated from Golang structs */


export enum LocationState {
    Pending = "pending",
    Valid = "valid",
    TooFar = "tooFar",
}
export enum ControlState {
    Active = "active",
    InLine = "inLine",
    Inactive = "inactive",
}
export enum EffectSelector {
    Solid = "solid",
    Twinkle = "twinkle",
    Sparkle = "sparkle",
}
export enum ModeSelector {
    Manual = "manual",
    Scenes = "scenes",
    Paint = "paint",
}
export interface externalCommands {
    id?: string;
    ping?: boolean;
    location?: number[];
    cue?: boolean;
    effect?: EffectSelector;
    mode?: ModeSelector;
    scene?: string;
    sprite?: string;
    colors?: string[];
    sparkles?: number[];
    paint?: boolean;
    paintColor?: string;
    tiltLR?: number;
    tiltFB?: number;
    dir?: number;
}
export interface externalEffectReference {
    name: string;
    index: number;
}
export interface ControlLimit {
    LimitSeconds: number;
    LimitName: string;
}
export interface Message {
    header: string;
    body: string;
    buttonLabel: string;
}
export interface Times {
    start: number;
    stop: number;
    calmStart?: number;
}
export interface externalStatus {
    state?: LocationState;
    control_state?: ControlState;
    active?: boolean;
    position?: number;
    id?: string;
    connected_locally?: boolean;
    times?: Times;
    message?: Message;
    control_limit?: ControlLimit;
    scenes?: externalEffectReference[];
    sprites?: externalEffectReference[];
}