import React, { lazy, ReactComponentElement, Suspense } from "react";
import EffectSelector, { effectStates } from "./EffectSelector";
import PresetSelector from "./PresetSelector";
import Server from "./Server";
import SpriteSelector from "./SpriteSelector";
import cookie from "react-cookies";
import Welcome from "./Welcome";
import Paint, { orientation } from "./Paint";
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tab, Tabs, Theme, Toolbar } from "@mui/material";
import { NavigateFunction, NavLink } from "react-router-dom";
import Logo from "./pwl_color.svg";

import serverInteractions, { serverSceneDefinition } from "sharedTypes/serverInteractions";
import LocationSelector from "./LocationSelector";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { Brush, Layers, Palette, Tune } from "@mui/icons-material";
import { ControlState, EffectSelector as effectSelector, externalEffectReference, externalStatus, LocationState, ModeSelector as modeSelector } from "./types";
import LocationManager from "./locationManager";

const ColorPallet = lazy(() => import("./ColorPallet"));

// let serverState: externalStatus = {};

const useStyles = (theme: Theme) =>
    createStyles({
        details: {
            // backgroundImage:""
            // backgroundColor: "#9099b8",
        },
        summary: {
            backgroundColor: theme.palette.primary.dark + " !important",
            color: "white",
        },
        summaryExpanded: {
            backgroundColor: theme.palette.primary.main + " !important",
            color: "white",
        },
        accordionElement: {
            margin: "0 !important",
            backgroundColor: "inherit"
        }
    });

interface Props extends WithStyles<typeof useStyles>  {
    sample?: boolean;
    locationError?: string | GeolocationPositionError;
    geoLocation: GeolocationPosition;
    overrideKey?: string | null;
}
class AppState extends React.Component<Props> {
    state = {
        effect: "twinkle",
        mode: "manual",
        connectedToLocation: false,
        serverConnected: false,
        activeControl: false,
        initialConnection: cookie.load("has_seen_animation") !== "true",

        hasAddedColor: cookie.load("has_added_color") === "true",
        hasChangedType: cookie.load("has_changed_type") !== "true",
        hasClickedSprite: cookie.load("has_clicked_sprite") === "true",

        dialogOpen: false,
        clientID: cookie.load("client_id"),

        // locations: {},
        serverState: {},

    } as {
        server: Server;

        effect: effectSelector;
        colors: Array<string>;
        sparkles: Array<number>;
        mode: modeSelector;
        preset: string;

        hasSeenLocationPrompt: boolean;
        connectedToLocation: boolean;
        initialConnection: boolean;

        hasAddedColor: boolean;
        hasChangedType: boolean;
        hasClickedSprite: boolean;

        activeControl: boolean;
        serverConnected: boolean;
        serverLocationState: "valid" | "pending" | "tooFar";

        locationManager: LocationManager | null;

        

        // locations: serverInteractions.serverMessage["locations"];
        // location?: string;

        serverState: externalStatus;
        clientID: string;

        dialogOpen: boolean;
    };

    componentDidMount = () => {
        this.setupServer();
        window.addEventListener("blur", this.onBlur);
    };

    onBlur = () => {
        return;
        
        // eslint-disable-next-line no-constant-condition
        if (this.props.sample) {
            return;
        }
        if (process.env.NODE_ENV == "production") {
            window.removeEventListener("blur", this.onBlur);
            console.log("blured");
            if (this.serverActive()) {
                this.teardownConnection();
                window.location.href = "/thanks";
            } else {
                this.teardownConnection();
            }
        }
    };

    componentWillUnmount = () => {
        this.teardownConnection();
    };

    teardownConnection = () => {
        if (this.state.server) {
            this.state.server.unload();
        }
        if (this.activePingInterval) {
            clearInterval(this.activePingInterval);
        }
        this.setState({
            server: null,
            locationManager: null,
            connectedToLocation: false,
            activeControl: false,
            serverState: {},
            location:  null,
        });
    };

    setupServer = () => {
        if (!this.state.server) {
            this.setState({
                server: new Server(
                    process.env.REACT_APP_SERVER
                        ? process.env.REACT_APP_SERVER
                        : "control.playwithlights.com",
                    8083,
                    {
                        connecting: this.onConnecting.bind(this),
                        connect: this.onConnect.bind(this),
                        disconnect: this.onDisconnect.bind(this),
                        reconnect: this.onReconnect.bind(this),
                        message: this.onMessage.bind(this),
                    }
                ),
            });
        }
    };

    // websocket things
    onConnecting = () => { };
    onConnect = () => {
        this.setState({ serverConnected: true });
        this.state.server.sendId(this.state.clientID ? this.state.clientID : "unset");
        setTimeout(() => {
            this.sendLocation();
            // this.sendOverrideKey();
        }, 0);
    };

    onDisconnect = () => {
        this.setState({ serverConnected: false });
        if (this.activePingInterval) {
            clearInterval(this.activePingInterval);
        }
    };
    onReconnect = () => {
        this.setState({ serverConnected: true });
        if (this.activePingInterval) {
            clearInterval(this.activePingInterval);
        }
    };

    animateInitialConnection = () => {
        cookie.save("has_seen_animation", "true", { maxAge: 2147483648 });

        this.setState({ initialConnection: false }, () => {
            this.clickManual();
            setTimeout(this.clickScenes, 1500);
            setTimeout(this.clickFlashlight, 750);
            // setTimeout(this.clickScenes, 1500);
        });
    };

    clickScenes = () => {
        if (this.scenesRef) {
            this.scenesRef.click();
        }
    };
    clickManual = () => {
        if (this.manualRef) {
            this.manualRef.click();
        }
    };
    clickFlashlight = () => {
        if (this.flashlightRef) {
            this.flashlightRef.click();
        }
    };

    componentDidUpdate = (prevProps: Props) => {
        if (prevProps.geoLocation != this.props.geoLocation) {
            this.sendLocation();
        }
    };

    sendLocation = () => {
        if (
            this.state.server &&
            this.state.serverConnected &&
            this.props.geoLocation?.coords
        ) {
            const loc = this.props.geoLocation;
            this.state.server.setLocation(
                loc.coords.latitude,
                loc.coords.longitude,
                loc.coords.accuracy
            );
        }
    };

    // sendOverrideKey = () => {
    //     if (
    //         this.state.server &&
    //         this.state.serverConnected &&
    //         this.props.overrideKey
    //     ) {
    //         this.state.server.setOverrideKey(this.props.overrideKey);
    //     }
    // };

    locationMetadata = (): externalStatus | undefined => {
        if (this.state.serverState && this.state.serverState.control_state == ControlState.Active){
            return this.state.serverState;
        }
        
        return undefined;
    };

    onMessage = (m: MessageEvent<any>) => {
        const incoming = JSON.parse(m.data) as externalStatus;
        const { serverState, activeControl } = this.state;

        Object.assign(serverState, incoming);
        
        if (incoming.id && incoming.id !== this.state.clientID) {
            this.setState({ clientID: incoming.id });
            cookie.save("client_id", incoming.id, { maxAge: 2147483648 });
        }



        // if (this.props.sample && incoming.location) {
        //     incoming.active = true;
        // }

        // if (incoming.locations) {
        //     const previousLocations = this.state.locations
        //         ? this.state.locations
        //         : {};

        //     // if we have a incoming location from the server use that
        //     const currentLocation = incoming.location ? incoming.location : this.state.location;

        //     for (const l in incoming.locations) {
        //         if (currentLocation == l) {
        //             // current location update

        //             this.setState({
        //                 presets: incoming.locations[l].scenes,
        //                 sprites: incoming.locations[l].sprites,
        //                 dialogOpen: incoming.locations[l].message && (this.state.dialogOpen || incoming.locations[l].message != previousLocations[l].message),
        //             });
        //         }

        //         previousLocations[l] = incoming.locations[l];
        //     }

        //     this.setState({ locations: previousLocations });
        // }

        // if (incoming.location) {
        //     const locationMetadata =
        //         this.state.locations && this.state.locations[incoming.location]
        //             ? this.state.locations[incoming.location]
        //             : undefined;

        //     if(locationMetadata){
        //         cookie.save("previous_location", incoming.location, { maxAge: 2147483648 });
        //     }

        //     this.setState({
        //         location: incoming.location,
        //         presets: locationMetadata?.scenes,
        //         sprites: locationMetadata?.sprites,
        //         dialogOpen: locationMetadata?.message != null,
        //     });
        // }


        if (incoming.control_state !== undefined) {
            this.setState(
                {
                    activeControl: incoming.control_state == ControlState.Active,
                },
                () => {
                    if (incoming.control_state == ControlState.Active) {
                        gtag("event", "active", {
                            event_category: "control",
                            event_label: "active",
                        });
                        this.serverSendAll();
                        // if (initialConnection) {
                        //     this.animateInitialConnection();
                        // }
                        this.activePingInterval = setInterval(() => {
                            this.serverSendAll();
                        }, 5000);
                    } else {
                        if (
                            incoming.state === LocationState.TooFar &&
                            activeControl
                        ) {
                            window.location.href = "/thanks";
                        }

                        gtag("event", "inactive", {
                            event_category: "control",
                            event_label: "inactive",
                        });
                        if (this.activePingInterval) {
                            clearInterval(this.activePingInterval);
                        }
                    }
                }
            );
        }

        // serverState = {...serverState, ...incoming};
        console.log("server state", this.state, incoming, serverState );

        this.setState({ serverState });
    };

    serverSendAll = () => {
        const { server, colors, effect, preset, mode } = this.state;

        if (this.serverActive()) {
            server.sendEverything({
                colors,
                location: this.props.geoLocation,
                effect,
                scene: preset,
                mode,
            });
        }
    };

    serverActive = () => {
        const { serverConnected, activeControl, server } = this.state;
        return serverConnected && activeControl && server && !this.props.sample;
    };

    onColorsChange = (colors: Array<string>, sparkles: Array<number>, manual: boolean) => {
        if(manual){
            if(!this.state.hasAddedColor && colors.length != 2){
                cookie.save("has_added_color", "true", { maxAge: 2147483648 });
            }
            this.setState({ colors, sparkles, hasAddedColor: true });
        }

        if (this.serverActive()) {
            this.state.server.setColors(colors, sparkles);
        }
    };

    onSelectPreset = (preset?: externalEffectReference) => {
        if(preset){
            this.setState({ preset: preset.name });
            if (this.serverActive()) {
                this.state.server.setScene(preset.name);
            }
        }
    };

    onSprite = (sprite: string) => {
        if (this.serverActive()) {
            if(!this.state.hasClickedSprite){
                cookie.save("has_clicked_sprite", "true", { maxAge: 2147483648 });
                this.setState({ hasClickedSprite: true });
            }

            gtag("event", "active_sprite_click", {
                event_category: "active",
                event_label: "Active user clicked sprite",
            });

            this.state.server.sendSprite(sprite);
        }
    };

    onSelectEffect = (effect: effectSelector) => {
        this.setState({ effect });
        if (this.serverActive()) {
            this.state.server.setEffect(effect);
        }
    };

    // onSelectLocation = (locationID: string) => {
    //     if (this.state.serverConnected && this.state.server) {


    //         gtag("event", "active_change_location", {
    //             event_category: "location",
    //             event_label: "Active user changed location",
    //         });

    //         this.state.server.setLocationID(locationID);
    //     }
    // };

    onSelectMode = (_event: React.SyntheticEvent, newValue: number) => {
        let mode = modeSelector.Manual;
        switch (newValue) {
        case 0:

            gtag("event", "mode_manual", {
                event_category: "mode",
                event_label: "Playing Manual",
            });

            mode =  modeSelector.Manual;
            break;
        case 1:

            gtag("event", "mode_scenes", {
                event_category: "mode",
                event_label: "Playing scenes",
            }); 

            mode =  modeSelector.Scenes;
            break;
        case 2:

            gtag("event", "mode_paint", {
                event_category: "mode",
                event_label: "Playing flashlight",
            });
        
            mode = modeSelector.Paint;
            break;
        }

        this.setState({ mode, hasChangedType: true });

        if (this.serverActive()) {
            gtag("event", "mode_" + mode, {
                event_category: "mode",
                event_label: mode,
            });

            this.state.server.setMode(mode);
        }
    };


    onPaintUpdate = (paint: boolean, color: string) => {
        if (this.serverActive()) {
            if (paint) {
                this.state.server.paintOn(color);
            } else {
                this.state.server.paintOff();
            }
        }
    };

    onOrientationUpdate = (deviceOrientation: orientation) => {
        if (this.serverActive() && this.state.mode ===  modeSelector.Paint) {
            if (
                deviceOrientation.tiltLR &&
                deviceOrientation.tiltFB &&
                deviceOrientation.dir
            ) {
                this.state.server.sendOrientation(
                    deviceOrientation.tiltLR,
                    deviceOrientation.tiltFB,
                    deviceOrientation.dir
                );
            }
        }
    };

    onReCue = () => {
        const { serverConnected } = this.state;
        if (serverConnected) {
            gtag("event", "recue", {
                event_category: "active",
                event_label: "Got back in line",
            });

            this.setState({ refocusEvent: false }, () => {
                this.state.server.cue();
            });
        }
    };

    a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    activePingInterval: NodeJS.Timeout | undefined;

    manualRef?: HTMLDivElement | null;
    scenesRef?: HTMLDivElement | null;
    flashlightRef?: HTMLDivElement | null;

    closeDialog = () => {
        this.setState({ dialogOpen: false });
    };

    render() {
        const { mode, activeControl } = this.state;
        const locationMeta = this.locationMetadata();

        gtag("event", "active", {
            event_category: "active",
            event_label: "Playing with the lights",
        });

        const activeControlContent = (
            <Box>

                <Box style={{marginLeft:"32px", marginRight:"32px", marginTop:"32px", marginBottom:"16px"}}>
                    <NavLink to="/">
                        <img
                            style={{
                                marginTop: "0px",
                                width: "100%",}}
                            color="white"
                            src={Logo}
                            alt="PlayWithLights.com"
                        />
                    </NavLink>
                
                    {/* <LocationSelector serverPing={this.state.server?.ping} onSelectLocation={this.onSelectLocation} location={this.state.location} locations={this.state.locations} /> */}
                </Box>

                {locationMeta?.message ? (
                    <Dialog
                        sx={{backgroundColor:"rgba(0,0,0,.5)"}}
                        open={this.state.dialogOpen}
                        onClose={this.closeDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {locationMeta?.message.header}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {locationMeta?.message.body}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.closeDialog}
                                variant="contained"
                                color="primary"
                            >
                                {locationMeta?.message.buttonLabel}
                            </Button>
                        </DialogActions>
                    </Dialog>
                ) : undefined}


                <AppBar position="static">

                    {/* <Box sx={{ borderBottom: 2, borderColor: "divider" }}> */}
                    <Tabs variant="fullWidth" value={mode == "manual" ? 0 : (mode == "scenes" ? 1 : 2)} onChange={this.onSelectMode} aria-label="basic tabs example">
                        <Tab icon={<Palette/>} iconPosition="start" label="Manual" {...this.a11yProps(0)} />
                        <Tab icon={<Layers/>} iconPosition="start" label="Scenes" {...this.a11yProps(1)} />
                        <Tab icon={<Brush/>} iconPosition="start"  label="Paint" {...this.a11yProps(2)} />
                    </Tabs>
                    {/* </Box> */}
                </AppBar>

                <TabPanel mode={mode} value={modeSelector.Manual}>

                    <SpriteSelector
                        sprites={this.state.serverState?.sprites}
                        onSpriteSelect={this.onSprite}
                    />
                    
                    
                    <EffectSelector
                        effect={this.state.effect}
                        onSelectEffect={this.onSelectEffect}
                    />
                    <Suspense fallback={<Box>Loading</Box>}>
                        <ColorPallet hasChangedColor={this.state.hasAddedColor} onColorsChange={this.onColorsChange} />
                    </Suspense>
                </TabPanel>

                <TabPanel mode={mode} value={modeSelector.Scenes}>

                    <SpriteSelector
                        sprites={this.state.serverState?.sprites}
                        onSpriteSelect={this.onSprite}
                    />
                    
                    <PresetSelector
                        onPresetSelect={this.onSelectPreset}
                        presets={this.state.serverState?.scenes}
                    />

                </TabPanel>
                <TabPanel mode={mode} value={modeSelector.Paint}>

                    <Paint
                        active={mode === modeSelector.Paint}
                        onPaintUpdate={this.onPaintUpdate}
                        onOrientationUpdate={this.onOrientationUpdate}
                    />
                </TabPanel>


            </Box>
        );

        let content;
        if (activeControl) {
            content = activeControlContent;
        } else {
            content = (
                <Welcome
                    locationManager={this.state.locationManager}
                    reCue={this.onReCue}
                    serverState={this.state.serverState}
                    setupServer={this.setupServer}
                />
            );
        }

        return <Box>{content}</Box>;
    }
}


interface TabPanelProps {
    children?: React.ReactNode;
    mode: string;
    value: modeSelector;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, mode, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== mode}
            {...other}
        >
            {value === mode && (
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default withStyles(useStyles, { withTheme: true })(AppState);

// export default ColorPicker
