import { Box, Button, ButtonGroup, Theme } from "@mui/material";
import React from "react";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { serverSceneDefinition } from "sharedTypes/serverInteractions";
import { externalStatus } from "./types";


const useStyles = (theme: Theme) =>
    createStyles({
        root: {
            // top: "auto",
            // bottom: "50px",
            // position: "fixed",
            // width:"100%",
            marginTop: "0px",
            width: "100%",
            marginBottom: theme.spacing(1.5),
        },
        button:{
            backgroundColor: "rgba(255,255,255,.2) !important",
            // height: "38px"
            padding: "5px !important",
        }
    });

interface Props extends WithStyles<typeof useStyles>{ 
    sprites: externalStatus["sprites"];
    onSpriteSelect: (s: string)=>void;
}

class SpriteSelector extends React.Component<Props> {
    state = {    };

    onSprite = (s: string)=>{
        this.props.onSpriteSelect(s);
    };

    render(){
        const {classes} = this.props;

        const presets = [];
        if(this.props.sprites){
            for(const id of this.props.sprites){
                presets.push(<Button className={classes.button} key={id.index} onClick={(_e: React.MouseEvent)=>this.onSprite(id.name)}>{id.name}</Button>);
            }
        }

        return (
            <Box className={classes.root} display="flex" justifyContent="center">
                <ButtonGroup size="small" variant="outlined" aria-label="outlined primary button group">
                    {presets}
                </ButtonGroup>
            </Box>
        );
    }   
}

export default withStyles(useStyles, { withTheme: true })(SpriteSelector);
