import {  Button, ButtonGroup, Theme } from "@mui/material";
import React from "react";
import {createStyles, WithStyles, withStyles} from "@mui/styles";
import { serverSceneDefinition } from "sharedTypes/serverInteractions";
import { externalEffectReference, externalStatus } from "./types";

const useStyles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            // backgroundColor: theme.palette.grey[500],
        },
    });

interface Props extends WithStyles<typeof useStyles>{ 
    presets: externalStatus["scenes"]
    onPresetSelect: (preset?:externalEffectReference)=>void
}

class PresetSelector extends React.Component<Props> {
    state = {
        selected: this.props.presets ?this.props.presets[0]: undefined,
    };

    constructor(p:Props){
        super(p);
        this.props.onPresetSelect(this.state.selected);
    }

    onPreset = (ext: externalEffectReference)=>{
        this.setState({selected:ext}, ()=>{
            this.props.onPresetSelect(ext);
        });
    };


    render(){
        const {classes} = this.props;
        const {selected} = this.state;

        const presets = [];
        if(this.props.presets){
            for(const preset of this.props.presets){
                presets.push(<Button key={preset.index} onClick={(_e: React.MouseEvent)=>this.onPreset(preset)} variant={selected===preset?"contained":"outlined"}>{preset.name}</Button>);
            }
        }

        return (
            <ButtonGroup disableElevation className={classes.root}  orientation="vertical" aria-label="outlined primary button group">
                {presets}
            </ButtonGroup>
        );
    }   
}

export default withStyles(useStyles, { withTheme: true })(PresetSelector);
