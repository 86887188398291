import { effectStates } from "./EffectSelector";
import { EffectSelector, externalCommands, externalEffectReference, ModeSelector } from "./types";

class Server {
    connectionString: string;
    events: any;
    firstConnect: boolean;
    lastOrientationSent: number | null;
    _dirCalibration: number;
    _lastOrentationData: {};
    _socket?: WebSocket;
    id: string;

    constructor(host: any, port: any, events: any) {
    // this.connect = this.connect.bind(this);
    // this.unload = this.unload.bind(this);
    // this.connected = this.connected.bind(this);
    // this.close = this.close.bind(this);
        // if(host == "localhost"){
        //     this.connectionString = "ws://localhost:8084";
        // }else{
        //     this.connectionString = `wss://${host}`;
        // }

        this.connectionString = `wss://${host}/play`;

        this.events = events;
        this.firstConnect = false;
        this.lastOrientationSent = null;
        this._dirCalibration = 0;
        this._lastOrentationData = {};
        this.id = "unsetid";
        this.connect();
    }

    connect = () => {
        if ([0, 1].includes(this._socket?.readyState as number)) {
            return;
        }
        // console.log("ACTUALLY CONNECTING CALLED FOR SERVER", this.id);

        this._socket = new WebSocket(this.connectionString);
        this._socket.onclose = this.close;
        this._socket.onopen = this.connected;
        this._socket.onmessage = this.events.message;
        return this.events.connecting();
    };

    unload = () => {
        if (this._socket) {
            this._socket.onclose = null;
            this._socket.close();
        }
    };

    connected = () => {
    // console.log("connected CALLED FOR SERVER", this.id);

        this.events.connect();
        if (!this.firstConnect) {
            return (this.firstConnect = true);
        } else {
            return this.events.reconnect();
        }
    };

    close = () => {
        this.events.disconnect();
        return setTimeout(this.connect, 1000);
    };


    send = (message: Partial<externalCommands>) => {
        if (this._socket) {
            this._socket.send(JSON.stringify(message));
        }
    };

    
    //   calibrateDirection(){
    //     if (this._lastOrentationData.dir < 180) {
    //       return this._dirCalibration = this._lastOrentationData.dir * -1;
    //     } else {
    //       return this._dirCalibration = 360 - this._lastOrentationData.dir;
    //     }
    //   }

    sendEverything = ({
        colors,
        effect,
        location,
        scene,
        mode,
    }: {
    colors: Array<string>;
    effect: EffectSelector;
    location?: GeolocationPosition;
    scene: string;
    mode: ModeSelector;
  }) => {
        let processedLocation;

        if (location) {
            processedLocation = [
                location.coords.latitude,
                location.coords.longitude,
                location.coords.accuracy,
            ] as [lat: number, lon: number, accuracy: number];

            if (process.env.NODE_ENV != "production") {
                processedLocation[0] = 47.7669591;
                processedLocation[1] = -122.1958623;
                processedLocation[2] = 1;
            }
        }

        return this.send({
            ping: true,
            colors,
            effect,
            location: processedLocation,
            scene,
            mode,
        });
    };

    sendOrientation = (tiltLR: number, tiltFB: number, dir: number) => {
        this._lastOrentationData = { tiltLR, tiltFB, dir };
        if (
            Date.now() - 30 >
      (this.lastOrientationSent ? this.lastOrientationSent : 0)
        ) {
            let computed = (dir + this._dirCalibration) % 360;
            if (computed < 0) {
                computed += 360;
            }

            // normalize to 180 with a standard range of 90 - 270

            if (computed > 270 && computed < 360) {
                this._dirCalibration--;
            } else if (computed < 90 && computed > 0) {
                this._dirCalibration++;
            }

            let dirToSend = (dir + this._dirCalibration) % 360;
            if (dirToSend < 0) {
                dirToSend += 360;
            }

            this.send({ tiltLR, tiltFB, dir: dirToSend });
            this.lastOrientationSent = Date.now();
        }
    };

    cue = () => {
        return this.send({ cue: true });
    };

    paintOn = (color: any) => {
        return this.send({ paint: true, paintColor: color });
    };

    paintOff = () => {
        return this.send({ paint: false });
    };

    sendId = (id: any) => {
        return this.send({ id });
    };

    ping = () => {
        return this.send({ ping: true });
    };

    sendSprite = (sprite: any) => {
        return this.send({ sprite });
    };

    setMode = (mode: ModeSelector) => {
        return this.send({ mode });
    };

    setScene = (scene: string) => {
        return this.send({ scene });
    };

    setEffect = (name: EffectSelector) => {
        return this.send({ effect: name });
    };

    setColors = (colors: Array<string>, sparkles?: Array<number>) => {
        return this.send({ colors , sparkles});
    };

    setLocation = (lat: any, lon: any, accuracy: any) => {
        if (process.env.NODE_ENV != "production") {
            lat = 47.7669591;
            lon = -122.1958623;
        }

        return this.send({ location: [lat, lon, accuracy] });
    };

    // setLocationID = (locationID: string) => {
    //     return this._send({ locationID: locationID });
    // };

    // setOverrideKey = (overrideKey: string) => {
    //     return this._send({ overrideKey });
    // };
}

export default Server;
