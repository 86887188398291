import {
    Box,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Link,
    Theme,
    Typography,
} from "@mui/material";
import React from "react";
import BulbOn from "../bulbOn.svg";
import BulbOff from "../bulbOff.svg";

import { hoursToTimeString } from "../lib/time";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import LogoBox from "../prims/LogoBox";

const useStyles = (theme: Theme) => {
    return createStyles({
        root: {
        },

        fullWidthButton: {
            marginTop: theme.spacing(3),
            width: "100%",
        },
        listItemAvatar: {
            minWidth: "34px",
        },
        inline: {
            display: "inline",
        },
        bulbIndicator: {
            height: "20px",
            marginRight: "10px",
            float: "left",
        },

    });
};

interface Props extends WithStyles<typeof useStyles> {
}

type PlayWithLightsLocation = {
    _id: string,
    dis: number,
    lastSeen: number;
    name: string;
    address: string;
    online: boolean;
    times: { start: number; stop: number };
    turnedOn: boolean;
}

class Locations extends React.Component<Props> {
    state = {
        locations: [],
        receivedResults: false,
    } as {
        locations: Array<PlayWithLightsLocation>;
        receivedResults: boolean;
    };

    render() {
        const { classes } = this.props;

        gtag("event", "info", {
            "event_category": "static",
            "event_label": "Info page"
        });

        // const locations: Array<JSX.Element> = [];

        // for (const loc of this.state.locations) {

        //     const actuallyOnRightNow = loc.turnedOn && loc.online;

        //     const link = "https://www.google.com/maps/dir//" + encodeURIComponent(loc.address);

        //     locations.push(
        //         <ListItem key={loc._id}>

        //             <ListItemText
        //                 primary={
        //                     <React.Fragment>
        //                         <img src={actuallyOnRightNow ? BulbOn : BulbOff} className={classes.bulbIndicator} />
        //                         {loc.name} {!loc.online? "- Currently Disconnected":""}

        //                     </React.Fragment>
        //                 }
        //                 secondary={
        //                     <React.Fragment>
        //                         <Typography
        //                             component="span"
        //                             variant="body2"
        //                             className={classes.inline}
        //                             color="textPrimary"
        //                         >
        //                         </Typography>
        //                         {loc.dis.toFixed(2) + " miles - " + hoursToTimeString(loc.times)}
        //                         <Link style={{ display: "box", float: "right" }} href={link} color="primary">Directions</Link>
        //                     </React.Fragment>
        //                 }
        //             />

        //         </ListItem>);
        // }

        const link = "https://www.google.com/maps/dir//" + encodeURIComponent("10822 NE 193rd St, Bothell WA 98011");

        return (

            <LogoBox>

                <Box>
                    <Box>
                        <h1 style={{ marginBottom: "0px" }}>Displays</h1>
                        <List disablePadding={true} dense >
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <img src={BulbOn} className={classes.bulbIndicator} />
                                            Berolina in Bothell Washington 4-11PM
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                            </Typography>
                                                Multiple houses in the same Bothell neighborhood, all synced together.
                                            <Link style={{ display: "box", float: "right" }} href={link} color="primary">Directions</Link>
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Box>
                
                </Box>
            


            </LogoBox>
        );
    }
}



export default withStyles(useStyles, { withTheme: true })(Locations);
