import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppState from "./AppState";
import Info from "./info/Info";
import { alpha, Box, createTheme, GlobalStyles, ThemeProvider } from "@mui/material";
import {
    BrowserRouter,
    Routes,
    Route,
    NavigateFunction,
} from "react-router-dom";
import Thanks from "./info/Thanks";
import Locations from "./info/Locations";
import LocationManager from "./locationManager";
import LocationError from "./info/LocationError";
import cookie from "react-cookies";
import LocationPermission from "./info/LocationPermission";
import LocationAccessRequired from "./LocationAccessRequired";
import { createStyles, withStyles, WithStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";

declare module "@mui/material/styles" {
    interface Palette {
        neutral: Palette["primary"];
    }

    interface PaletteOptions {
        neutral: PaletteOptions["primary"];
    }

}

declare module "@mui/material" {
    interface ButtonGroupPropsColorOverrides {
        neutral: true;
    }
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
    interface AppBarPropsColorOverrides {
        neutral: true;
    }
}


const theme = createTheme({
    components: {
        MuiButton: {
            defaultProps:{
                color: "neutral"
            },
                        
            styleOverrides:{
                outlined:{
                    color: "white",
                },
            }
        },
        MuiButtonGroup: {
            defaultProps: {
                color: "neutral",
            },

            
        }
        //     defaultProps:{
        //         style:{color: "white"},
        //     },

        //     styleOverrides: {
                
        //         contained:{
        //             border: "10",
        //             color: "#000",
        //         },
        //         containedPrimary: {

        //             marginTop: "8px",
        //             borderColor: "#006869",
        //             backgroundColor: "#ccc",
        //             color: "#000",
        //             "&:hover": {
        //                 backgroundColor: alpha(
        //                     "#ccc",
        //                     .5
        //                 )
        //             }
        //         },
        //         outlinedPrimary: {

        //             marginTop: "8px",

        //             color: grey[100],
        //             "&:hover": {
        //                 backgroundColor: alpha(
        //                     "#fff",
        //                     .5
        //                 )
        //             }
        //         },
        //         textPrimary: {
        //             marginTop: "8px",

        //             color: grey[100],
        //         }
        //     },
        // },

    },


    palette: {
        mode: "dark",
        // text:{
        //     primary: "#fff",
        //     secondary: "#ddd",
        // },
        background: {
            // paper:"#c9def3",
            default: "#023c58",
            paper: "rgba(66,66,66,0.44)",
            // default:"#fff"
        },
        neutral: {
            main: grey[300],
            dark: grey[400],
            contrastText: "#000"
        },

        primary: {
            main: "#8bc34a",
        },

        secondary: {
            main: "#f50057",
        },
    },
});

const useStyles = () =>
    createStyles({
        body: {
            width: "100%"
        },

        root: {
            "@media only screen and (min-width: 700px)": {
                maxWidth: "700px",
                margin: "auto"
            },
        },

        bg:{
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: -1,
            transform: "scale(1.0)",
            position: "fixed",


            backgroundColor: "#023c58",
            backgroundImage: "url('/treeBaseBlue.png')",
            backgroundRepeat: "repeat-x",
            backgroundAttachment: "fixed",
            backgroundPosition: "center bottom",
            
        },



    });

const locationDebug = true;

interface Props extends WithStyles<typeof useStyles> {}

class App extends React.Component<Props> {
    state = {
        initialPlay:
            ((document.referrer === "" && window.location.pathname === "/") ||
                document.referrer.startsWith("https://christmascontrol.com/")) &&
            // eslint-disable-next-line no-restricted-globals
            (screen.width <= 699 ||
                navigator.userAgent.match(/iPhone|iPod|Android|iPad/i)),

        hasSeenLocationPrompt: cookie.load("seen_location_prompt_2") === "true",
        // overrideKey: new URLSearchParams(window.location.search).get("k")
    } as {
        initialPlay: boolean;
        locationManager: LocationManager;
        locationError?: string | GeolocationPositionError;
        location: GeolocationPosition;
        hasSeenLocationPrompt: boolean;
        overrideKey: string | null;
    };

    setupLocationManager = () => {
        if (!this.state.locationManager && this.state.hasSeenLocationPrompt) {
            const lm = new LocationManager({
                onChange: this.onLocationChange,
                onError: this.onLocationError,
            });
            this.setState({ locationManager: lm });
        } else {
            if (this.state.location) {
                this.onLocationChange(this.state.location);
            }
        }
        // this.state.server.setLocation(47.7691904,-122.2049792,1537);
    };

    onHasSeenLocationPrompt = () => {
        gtag("event", "location_permission_grant", {
            event_category: "location",
            event_label: "Location permission granted",
        });
        cookie.save("seen_location_prompt_2", "true", { maxAge: 2147483648 });
        this.setState({ hasSeenLocationPrompt: true }, () => {
            this.setupLocationManager();
        });
    };

    onLocationChange = (pos: GeolocationPosition) => {
        if (pos != this.state.location) {
            this.setState({ location: pos });
        }
        if (locationDebug) {
            console.log("location change", pos);
        }
    };

    onLocationError = (err: string | GeolocationPositionError) => {
        if (typeof(err) != "string" && err.code == GeolocationPositionError.POSITION_UNAVAILABLE){
            console.log("recoverable err?");
        }
        if (locationDebug) {
            console.log("location error", err);
        }
        if (!this.state.overrideKey) {
            this.setState({ locationError: err });
        }
    };

    componentDidMount() {
        if (window.location.host === "christmascontrol.com") {
            window.location.assign("https://playwithlights.com");
        }

        this.setupLocationManager();
    }

    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles styles={{
                    body: {
                        // backgroundColor: "#023c58",
                        // backgroundImage: "url('treeBaseBlue.png')",
                        // backgroundRepeat: "repeat-x",
                        // backgroundAttachment: "fixed",
                        // backgroundPosition: "center bottom",
                    }
                }} />
                <Box className={classes.bg}/>
                <Box className={classes.root}>
                    <Routes>
                        <Route path="/locationPermission" element={<LocationPermission onSeenLocationPrompt={this.onHasSeenLocationPrompt} />}/>
                        <Route path="/locationError" element={<LocationError locationError={this.state.locationError!} />}/>

                        <Route path="/locations" element={
                            <Locations  />
                        }/>
                        <Route path="/" element={<Info />}/>
                        <Route path="/thanks" element={<Thanks />}/>
                        <Route path="/sample" element={
                            <LocationAccessRequired hasSeenLocationPrompt={this.state.hasSeenLocationPrompt} locationError={this.state.locationError}>
                                <AppState sample={true} geoLocation={this.state.location} locationError={this.state.locationError} overrideKey={this.state.overrideKey} />
                            </LocationAccessRequired>
                        }/>
                        <Route path="/play" element={
                            <LocationAccessRequired hasSeenLocationPrompt={this.state.hasSeenLocationPrompt} locationError={this.state.locationError}>
                                <AppState geoLocation={this.state.location} locationError={this.state.locationError} overrideKey={this.state.overrideKey} />
                            </LocationAccessRequired>
                        }/>
                    </Routes>
                </Box>
                {/* {this.state.initialPlay ? (
            <Redirect exact from="/" to="/play" />
          ) : undefined} */}
            </ThemeProvider>
        );
    }
}

export default withStyles(useStyles, { withTheme: true })(App);

