import { Times } from "../types";

const hoursToTimeString =(hours?: Times):string => {
    let timeString = "";
    if (hours) {
        const timeStartStringHour = hours.start % 12 | 0;
        let timeStartStringMinute: string | number = Math.round(
            (hours.start % 1) * 60
        );
        if (timeStartStringMinute < 10) {
            timeStartStringMinute = "0" + timeStartStringMinute;
        }

        const stop = hours.calmStart? hours.calmStart : hours.stop;

        const timeStartStringAP = hours.start > 12 ? "PM" : "AM";
        let timeStopStringHour = stop % 12 | 0;
        let timeStopStringMinute: string | number = Math.round(
            (stop % 1) * 60
        );
        if (timeStopStringMinute < 10) {
            timeStopStringMinute = "0" + timeStopStringMinute;
        }
        const timeStopStringAP = stop > 12 ? "PM" : "AM";

        if (stop == 0){
            timeStopStringHour = 12;
        }

        timeString =
timeStartStringHour +
":" +
timeStartStringMinute +
timeStartStringAP +
" - " +
timeStopStringHour +
":" +
timeStopStringMinute +
timeStopStringAP;
    }
    return timeString; 
};

export {hoursToTimeString};